//@import "../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";

.cart-open {
  overflow: hidden;
}

main {
  background: transparent;
  font-size: 2rem;
}

.App {
  text-align: center;
  font-family: 'Righteous', cursive;
  hyphens: auto;
  line-height: 1.1;
  background: transparent;
}

.App-logo {
  pointer-events: none;
  position: fixed;
  top: 5px;
  left: 0;
  z-index: 10000;
  line-height: 0.8rem;
  padding: 0.8rem;

  @media(max-width: 725px) {
    display: none;
  }
}

.App-header {
  background-size: 100vw;
  background: #282c34 url('../public/header.jpg') no-repeat fixed center top;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: white;
}

.App-nav {
  display: block;
  width: 100vw;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 9999;
  font-size: 2rem;

  @media(max-width: 725px) {
    font-size: 1.5rem;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
    align-content: center;
    align-items: center;
    padding: 0 10px;
    margin: 0;
    overflow: hidden;
    background-color: #282c34;

    li {
      padding: 10px;

      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;
      }

      .cart-icon {
        cursor: pointer;
      }

      .cart-count {
        transform: scale(0.8) translateY(-14px);
      }
    }
  }
}

.App-footer {
  background-color: #282c34;
  color: rgba(255,255,255,0.8);
  padding: 10px 0;
}

.App-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 200ms ease;
  font-size: 8rem;
  font-style: normal;
  font-weight: 400;
  cursor: default;
  text-shadow:
    3px 3px 0 rgba(0,0,0,0.2),
    -3px 3px 0 rgba(0,0,0,0.2),
    0 0 0 rgba(0,0,0,0.2),
    0 0  0 rgba(0,0,0,0.2);

  &:hover {
    color: #ffffff;
    text-decoration: none;
  }

  @media(max-width: 725px) {
    font-size: 6rem;
  }
  @media(max-width: 480px) {
    font-size: 5rem;
  }
  @media(max-width: 320px) {
    font-size: 4rem;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-content {
  padding: 20px 0;

  &.about {
    background: rgba(0,0,0,0.1);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 35%, rgba(0,0,0,0.0) 100%);

    .about-container {
      display: flex;
      padding: 0 20%;

      div {
        padding: 40px;
      }

      @media(max-width: 1024px) {
        display: flex;
        flex-direction: column;
        padding: 20px;
      }

      .profile-image {
        border-radius: 50%;

        @media(max-width: 480px) {
          width: 90%;
          height: 90%;
        }
      }

      .about-text-container {
        text-align: left;
      }
    }
  }

  &.contact {
    padding: 20px 50px;
    background: rgba(0,0,0,0.1);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 35%, rgba(0,0,0,0.0) 100%);

    .contact-text-container {
      display: inline-flex;
      text-align: center;

      .social_logo {
        width: 50px;
        height: 50px;
        margin: 10px;
      }
    }
  }
}

.App-content-main-right {
  padding: 5vw;

  img {
    width: auto;
    //height: 90vh;
  }
}

.App-content-main-left {
  ul {
    display: inline-flex;

    li {
      cursor: pointer;
      text-align: center;
      list-style: none;
      padding: 0 10px;
      opacity: 0.5;
      transition: opacity 0.5s linear;

      &.active {
        opacity: 1;
        transition: opacity 0.5s linear;
      }
    }
  }
}

.sticky-element {
  position: sticky;
  top: 40px;

  p {
    word-break: keep-all;
  }
}

.fade-in {
  position: relative;

  .loading-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0;
    z-index: -1;
    transition: opacity linear 0.1s;
  }

  &.loading {
    .loading-overlay {
      height: 100%;
      width: 100%;
      opacity: 40%;
      z-index: 9999;
      transition: opacity linear 0.1s;
    }
  }
}


// lds roller
.lds-roller {
  /* change color here */
  color: #abbd81;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.intersection {
  height: 100vh;
  width: 100vw;
  background: rgba(0.0.0.0.1);
}

.parallax-container {
  @media(max-width: 725px) {
    aspect-ratio: 4 / 1 !important;
  }
  @media(max-width: 1024px) {
    aspect-ratio: 5 / 1 !important;
  }
}

.parallax-header {
  height: 100%;

  h2 {
    color: rgba(255,255,255,0.9);
    font-size: 10rem;
    text-shadow:
      3px 3px 0 rgba(0,0,0,0.2),
      -3px 3px 0 rgba(0,0,0,0.2),
      0 0 0 rgba(0,0,0,0.2),
      0 0 0 rgba(0,0,0,0.2);
    @media(max-width: 725px) {
      font-size: 5rem;
    }
  }
}

.has-small-font-size {
  overflow-wrap: break-word;
}

.shop-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product-card {
  width: 600px;
  padding: 15px;
  max-width: 100vw;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 600px;
  max-width: 90vw;
  margin: 0 auto;
}

.product-image-container {
  display: flex;
  overflow: hidden; /* Hide overflow content */
  scroll-behavior: smooth; /* Smooth scrolling */
  width: 560px;
  height: 560px;
  max-height: 90vw;
  flex-direction: row;

  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }

  .scroll-button.left {
    left: 10px; /* Position to the left */
  }

  .scroll-button.right {
    right: 10px; /* Position to the right */
  }

  img {
    flex: 1;
    width: 560px;
    height: 560px;
    max-width: 80vw;
    max-height: 80vw;
    object-fit: cover;
    object-position: center;
    margin: 0 5px;
  }

  & img:first-child {
    margin-left: 0;
  }

  & img:last-child {
    margin-right: 0;
  }
}

.hidden-product-thumbnails-container {
  display: none;
}

// cart
.cart-overlay {

  .cart-overlay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .cart-overlay-content {
    z-index: 1000;
    position: absolute;
    top: 10vw;
    left: 25%;
    width: 50vw;
    height: 80vh;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    padding: 1% 10%;
    color: black;

    @media(max-width: 725px) {
      left: 5vw;
      width: 90vw;
      top: 5vw;
      height: 90vh;
    }

    h1 {
      border-bottom: 1px black solid;
      margin-bottom: 30px;
      padding: 10px;
    }

    .cart {
      color: black;
      border-bottom: 1px solid black;

      ul {
        background-color: white;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
        }
      }
    }

    .user-information {
      margin-top: 20px;
    }
  }
}

.cart-title {
  color: black;
}

.cart-close-btn-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: black;

  button {
    border: none;
    background-color: white;
    transform: scaleX(1.5);
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale3d(2, 1.5, 2.5);
      transition: transform 0.2s ease-in-out;
    }
  }
}

.cart-summary {
  margin-bottom: 20px;
}

.cart-total {
  font-size: 18px;
  font-weight: bold;
}

.user-form input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  color: black;

  &::placeholder {
    color: #c2c2c2;
  }
}

.order-button {
  display: block;
  width: 100%;
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

.thank-you-message {
  text-align: center;
}

.error {
  color: red;
  text-align: right;
}
